import * as z from 'zod'

export const BuildingTypeCode = z.enum(['SingleHouse', 'TwinHouse', 'RowHouse'])
export type BuildingTypeCode = z.infer<typeof BuildingTypeCode>

export const BuildingType = z.object({
  code: BuildingTypeCode,
  hasApartments: z.boolean()
})
export type BuildingType = z.infer<typeof BuildingType>

export const BuildingTypeList = z.array(BuildingType)
export type BuildingTypeList = z.infer<typeof BuildingTypeList>
