import * as z from 'zod'
import { StringToDate } from '@digital-magic/react-common/lib/utils/zod'
import { AllowedAction, PositiveInt, Price, PriceNumber, TranslatedString, UrlString } from '@api/endpoints/types'
import { UpdateImagesOrderRequest } from '@api/endpoints/buildings'
import {
  OptionCode,
  OptionId,
  OptionValueDescription,
  OptionValueId,
  OptionValueName
} from '@api/endpoints/buildings/options'
import {
  BasicImageFileEntity,
  CreateImageWithPrimary,
  FileId,
  ImageFileEntity,
  TranslatedFileId
} from '@api/endpoints/files'
import { BuildingType, BuildingTypeCode } from '../buildingTypes'

export const FacadeColor = z.enum(['Light', 'Dark'])
export type FacadeColor = z.infer<typeof FacadeColor>

export const InteriorStyleType = z.enum(['Light', 'Neutral', 'Dark'])
export type InteriorStyleType = z.infer<typeof InteriorStyleType>

export const LayoutTypeId = z.string().uuid()
export type LayoutTypeId = z.infer<typeof LayoutTypeId>

export const LayoutTypeFreeOptionId = z.string().uuid()
export type LayoutTypeFreeOptionId = z.infer<typeof LayoutTypeFreeOptionId>

export const LayoutTypeOptionValueId = z.string().uuid()
export type LayoutTypeOptionValueId = z.infer<typeof LayoutTypeOptionValueId>

export const LayoutTypeStyleId = z.string().uuid()
export type LayoutTypeStyleId = z.infer<typeof LayoutTypeStyleId>

export const LayoutTypeName = z.string().min(2).max(20)
export type LayoutTypeName = z.infer<typeof LayoutTypeName>

export const LayoutTypeTitle = z.string().min(2).max(40)
export type LayoutTypeTitle = z.infer<typeof LayoutTypeTitle>

export const LayoutTypeDescription = z.string().min(2).max(1000)
export type LayoutTypeDescription = z.infer<typeof LayoutTypeDescription>

export const FloorNumber = z.number().int().min(1).max(2)
export type FloorNumber = z.infer<typeof FloorNumber>

export const ApartmentsCount = z.number().int().min(1).max(200)
export type ApartmentsCount = z.infer<typeof ApartmentsCount>

// TODO: This type is not used, name LayoutTypeView as LayoutType and remove this one
export const LayoutType = z.object({
  id: LayoutTypeId,
  buildingType: BuildingType,
  name: LayoutTypeName,
  title: TranslatedString(LayoutTypeTitle),
  description: TranslatedString(LayoutTypeDescription),
  floorsCount: FloorNumber,
  apartmentsCount: ApartmentsCount.optional(),
  virtualTourUrl: UrlString.optional(),
  visibleOnLanding: z.boolean(),
  orderNumber: PositiveInt,
  htmlContent: FileId.optional(),
  allowedActions: z.array(AllowedAction),
  createdAt: StringToDate,
  updatedAt: StringToDate
})
export type LayoutType = z.infer<typeof LayoutType>

export const LayoutTypeListItem = z.object({
  id: LayoutTypeId,
  buildingType: BuildingType,
  name: LayoutTypeName,
  floorsCount: FloorNumber,
  apartmentsCount: ApartmentsCount.optional(),
  orderNumber: PositiveInt,
  createdAt: StringToDate,
  updatedAt: StringToDate
})
export type LayoutTypeListItem = z.infer<typeof LayoutTypeListItem>

export const LayoutTypeList = z.array(LayoutTypeListItem)
export type LayoutTypeList = z.infer<typeof LayoutTypeList>

export const CreateLayoutTypeRequest = z.object({
  buildingType: BuildingTypeCode,
  name: LayoutTypeName,
  title: TranslatedString(LayoutTypeTitle),
  description: TranslatedString(LayoutTypeDescription),
  floorsCount: FloorNumber,
  apartmentsCount: ApartmentsCount.optional(),
  virtualTourUrl: UrlString.optional(),
  visibleOnLanding: z.boolean(),
  htmlContent: FileId.optional()
})
export type CreateLayoutTypeRequest = z.infer<typeof CreateLayoutTypeRequest>

// TODO: All Ordered items can be a polymorphic structure
export const LayoutTypeOrderedItem = z.object({
  item: LayoutTypeId,
  index: PositiveInt
})
export type LayoutTypeOrderedItem = z.infer<typeof LayoutTypeOrderedItem>

export const UpdateLayoutTypeOrderRequest = z.object({
  items: LayoutTypeOrderedItem.array()
})
export type UpdateLayoutTypeOrderRequest = z.infer<typeof UpdateLayoutTypeOrderRequest>

export const OptionOrderedItem = z.object({
  item: OptionId,
  index: PositiveInt
})
export type OptionOrderedItem = z.infer<typeof OptionOrderedItem>

export const UpdateOptionsOrderRequest = z.object({
  items: OptionOrderedItem.array()
})
export type UpdateOptionsOrderRequest = z.infer<typeof UpdateOptionsOrderRequest>

export const LayoutTypeImageListItem = ImageFileEntity.extend({
  facadeColor: FacadeColor,
  primaryImage: z.boolean(),
  primaryLandingImage: z.boolean()
})
export type LayoutTypeImageListItem = z.infer<typeof LayoutTypeImageListItem>

export const LayoutTypeImage = LayoutTypeImageListItem.extend({
  layoutTypeId: LayoutTypeId
})
export type LayoutTypeImage = z.infer<typeof LayoutTypeImage>

export const LayoutTypeImages = LayoutTypeImageListItem.array()
export type LayoutTypeImages = z.infer<typeof LayoutTypeImages>

export const CreateLayoutTypeImageRequest = CreateImageWithPrimary.extend({
  facadeColor: FacadeColor,
  primaryLandingImage: z.boolean().optional()
})
export type CreateLayoutTypeImageRequest = z.infer<typeof CreateLayoutTypeImageRequest>

export const SetPrimaryLayoutTypeImage = z.object({
  facadeColor: FacadeColor
})
export type SetPrimaryLayoutTypeImage = z.infer<typeof SetPrimaryLayoutTypeImage>

export const UpdateLayoutTypeImagesOrderRequest = UpdateImagesOrderRequest.extend({
  facadeColor: FacadeColor
})
export type UpdateLayoutTypeImagesOrderRequest = z.infer<typeof UpdateLayoutTypeImagesOrderRequest>

export const LayoutTypeFreeOption = z.object({
  id: LayoutTypeFreeOptionId,
  layoutTypeId: LayoutTypeId,
  optionId: OptionId,
  disabled: z.boolean(),
  createdAt: StringToDate,
  updatedAt: StringToDate
})
export type LayoutTypeFreeOption = z.infer<typeof LayoutTypeFreeOption>

export const LayoutTypeFreeOptions = LayoutTypeFreeOption.array()
export type LayoutTypeFreeOptions = z.infer<typeof LayoutTypeFreeOptions>

export const CreateLayoutTypeFreeOptionRequest = z.object({
  optionId: OptionId
})
export type CreateLayoutTypeFreeOptionRequest = z.infer<typeof CreateLayoutTypeFreeOptionRequest>

export const LayoutTypeOptionValue = z.object({
  id: LayoutTypeOptionValueId,
  layoutTypeId: LayoutTypeId,
  optionValueId: OptionValueId,
  price: Price,
  disabled: z.boolean(),
  valueOrderNumber: PositiveInt,
  optionOrderNumber: PositiveInt,
  createdAt: StringToDate,
  updatedAt: StringToDate
})
export type LayoutTypeOptionValue = z.infer<typeof LayoutTypeOptionValue>

export const OptionValueData = z.object({
  optionValueId: OptionValueId,
  price: PriceNumber,
  valueOrderNumber: PositiveInt
})
export type OptionValueData = z.infer<typeof OptionValueData>

export const CreateLayoutTypeOptionValuesRequest = z.object({
  options: OptionValueData.array()
})
export type CreateLayoutTypeOptionValuesRequest = z.infer<typeof CreateLayoutTypeOptionValuesRequest>

export const LayoutTypeStyle = z.object({
  id: LayoutTypeStyleId,
  layoutTypeId: LayoutTypeId,
  styleType: InteriorStyleType,
  additionalPrice: Price,
  documentId: TranslatedFileId.optional(),
  createdAt: StringToDate,
  updatedAt: StringToDate
})
export type LayoutTypeStyle = z.infer<typeof LayoutTypeStyle>

export const LayoutTypeStyles = LayoutTypeStyle.array()
export type LayoutTypeStyles = z.infer<typeof LayoutTypeStyles>

export const CreateLayoutTypeStyleRequest = z.object({
  styleType: InteriorStyleType,
  additionalPrice: PriceNumber,
  documentId: TranslatedFileId.optional()
})
export type CreateLayoutTypeStyleRequest = z.infer<typeof CreateLayoutTypeStyleRequest>

export const LayoutTypeStyleImage = ImageFileEntity.extend({
  layoutTypeStyleId: LayoutTypeStyleId,
  primaryImage: z.boolean()
})
export type LayoutTypeStyleImage = z.infer<typeof LayoutTypeStyleImage>

export const LayoutTypeStyleImageListItem = ImageFileEntity.extend({
  primaryImage: z.boolean()
})
export type LayoutTypeStyleImageListItem = z.infer<typeof LayoutTypeStyleImageListItem>

export const LayoutTypeStyleImages = LayoutTypeStyleImageListItem.array()
export type LayoutTypeStyleImages = z.infer<typeof LayoutTypeStyleImages>

// Views

export const LayoutTypeFreeOptionView = z.object({
  id: LayoutTypeFreeOptionId,
  optionId: OptionId,
  code: OptionCode,
  name: TranslatedString(z.string()),
  multiValue: z.boolean(),
  disabled: z.boolean(),
  createdAt: StringToDate,
  updatedAt: StringToDate
})
export type LayoutTypeFreeOptionView = z.infer<typeof LayoutTypeFreeOptionView>

export const LayoutTypeOptionValueView = z.object({
  id: LayoutTypeOptionValueId,
  optionValueId: OptionValueId,
  name: TranslatedString(OptionValueName),
  description: TranslatedString(OptionValueDescription).optional(),
  orderNumber: PositiveInt,
  price: Price,
  disabled: z.boolean(),
  createdAt: StringToDate,
  updatedAt: StringToDate
})
export type LayoutTypeOptionValueView = z.infer<typeof LayoutTypeOptionValueView>

export const LayoutTypeOptionView = z.object({
  optionId: OptionId,
  code: OptionCode,
  name: TranslatedString(z.string()),
  multiValue: z.boolean(),
  disabled: z.boolean(),
  orderNumber: PositiveInt,
  createdAt: StringToDate,
  updatedAt: StringToDate,
  values: LayoutTypeOptionValueView.array()
})
export type LayoutTypeOptionView = z.infer<typeof LayoutTypeOptionView>

export const LayoutTypeView = z.object({
  id: LayoutTypeId,
  buildingType: BuildingType,
  name: LayoutTypeName,
  title: TranslatedString(LayoutTypeTitle),
  description: TranslatedString(LayoutTypeDescription),
  floorsCount: FloorNumber,
  apartmentsCount: ApartmentsCount.optional(),
  virtualTourUrl: UrlString.optional(),
  visibleOnLanding: z.boolean(),
  orderNumber: PositiveInt,
  htmlContent: FileId.optional(),
  allowedActions: AllowedAction.array(),
  createdAt: StringToDate,
  updatedAt: StringToDate,
  images: LayoutTypeImages,
  styles: LayoutTypeStyle.array(),
  freeOptions: LayoutTypeFreeOptionView.array(),
  options: LayoutTypeOptionView.array()
})
export type LayoutTypeView = z.infer<typeof LayoutTypeView>

export const LayoutTypePublicView = z.object({
  title: TranslatedString(LayoutTypeTitle),
  description: TranslatedString(LayoutTypeDescription),
  orderNumber: PositiveInt,
  image: BasicImageFileEntity.optional(),
  virtualTourUrl: UrlString.optional()
})
export type LayoutTypePublicView = z.infer<typeof LayoutTypePublicView>

export const LayoutTypePublicViewList = z.array(LayoutTypePublicView)
export type LayoutTypePublicViewList = z.infer<typeof LayoutTypePublicViewList>
